import AntForm, { AntSchema, useAntForm } from "@9troisquarts/ant-form";
import { Card, Col, Row } from "antd";
import React, { useState } from "react";
import { useIntl } from "react-intl";
import { UserType } from "../../types";
import { castAttributesFromDefinition } from '@9troisquarts/wand'
import axiosClient from '../../../utils/axiosClient';

type EditPasswordProps = {
  user: UserType;
  resetPasswordToken: String;
};

const userDefinition = {
  password: 'String',
  passwordConfirmation: 'String',
  resetPasswordToken: 'String',
};

const EditPassword: React.FC<EditPasswordProps> = props => {
  const {
    user,
    resetPasswordToken
  } = props;
  
  const editPassword = (user: UserType) => axiosClient.put('/users/password', {
    user: { 
      ...user,
      resetPasswordToken: resetPasswordToken,
    },
  }).then((res) => {
    window.location.replace('/')
  }).catch(err => {
    setErrors(err.errors)
  });
  
  const onSubmit = () => editPassword(castAttributesFromDefinition(userDefinition, object));
  
  const { object, onChange } = useAntForm(user || { 'password': '', 'passwordConfirmation': '' });
  const [errors, setErrors] = useState<any | undefined>(undefined);
  const intl = useIntl();
  
  const EditPasswordSchema: AntSchema = [
    {
      name: 'password',
      label: intl.formatMessage({ id: 'devise.passwords.edit.new_password' }),
      input: {
        type: 'password',
      }
    },
    {
      name: 'passwordConfirmation',
      label: intl.formatMessage({ id: 'devise.passwords.edit.confirm_new_password' }),
      input: {
        type: 'password',
      }
    }
  ]

  return (
    <Row style={{ padding: '10rem', minHeight: '800px' }}>
      <Col xs={12} md={12} offset={6}>
        <Card>
          <h2>{intl.formatMessage({ id: 'devise.passwords.edit.change_your_password'})}</h2>
          <AntForm
            schema={EditPasswordSchema}
            object={object}
            layout="vertical"
            // @ts-ignore
            onChange={onChange}
            onSubmit={onSubmit}
            submitText={intl.formatMessage({ id: 'devise.passwords.edit.change_my_password' })}
            errors={errors}
          />
        </Card>
      </Col>
    </Row>
  )
};

export default EditPassword;