import AntForm, { AntSchema, useAntForm } from "@9troisquarts/ant-form";
import { Card, Col, Row } from "antd";
import React, { useState } from "react";
import { useIntl } from "react-intl";
import { UserType } from "../../types";
import { castAttributesFromDefinition } from '@9troisquarts/wand'
import axiosClient from '../../../utils/axiosClient';

type AcceptInvitationProps = {
  user: UserType;
  invitationToken: String;
};

const userDefinition = {
  password: 'String',
  passwordConfirmation: 'String',
  invitationToken: 'String',
};

const AcceptInvitation: React.FC<AcceptInvitationProps> = props => {
  const {
    user,
    invitationToken
  } = props;
  
  const acceptInvitation = (user: UserType) => axiosClient.put('/users/invitation', {
    user: { 
      ...user,
      invitationToken: invitationToken,
    },
  }).then((res) => {
    window.location.replace('/')
  }).catch(err => {
    setErrors(err.errors)
  });
  
  const onSubmit = () => acceptInvitation(castAttributesFromDefinition(userDefinition, object));
  
  const { object, onChange } = useAntForm(user || { 'password': '', 'passwordConfirmation': '' });
  const [errors, setErrors] = useState<any | undefined>(undefined);
  const intl = useIntl();
  
  const AcceptInvitationSchema: AntSchema = [
    {
      name: 'password',
      required: true,
      label: intl.formatMessage({ id: 'devise.invitations.edit.new_password' }),
      input: {
        type: 'password',
        // @ts-ignore
        placeholder: intl.formatMessage({ id: 'devise.invitations.edit.password_placeholder' }),
      }
    },
    {
      name: 'passwordConfirmation',
      required: true,
      label: intl.formatMessage({ id: 'devise.invitations.edit.confirm_new_password' }),
      input: {
        type: 'password',
        // @ts-ignore
        placeholder: intl.formatMessage({ id: 'devise.invitations.edit.password_placeholder' }),
      }
    }
  ]

  return (
    <Row style={{ padding: '10rem', minHeight: '800px' }}>
      <Col xs={12} md={12} offset={6}>
        <Card style={{ padding: '1.5rem', textAlign: 'center' }}>
          <section>
            <h2 style={{marginBottom: '5px'}}>{intl.formatMessage({ id: 'devise.invitations.edit.welcome'})}</h2>
            <div style={{color: '#FF715B'}}>
              <div style={{fontSize: '2rem', fontWeight: '600'}}>Formasup - DataFin</div>
              <div style={{fontSize: '.95rem', position: 'relative', top: '-10px' }}>Gestion des accords et des financements</div>
            </div>
          </section>
          <p style={{marginTop: '1rem', textAlign: 'left'}}>{intl.formatMessage({id: 'devise.invitations.edit.instructions' })}</p>
          <AntForm
            schema={AcceptInvitationSchema}
            object={object}
            layout="vertical"
            // @ts-ignore
            onChange={onChange}
            onSubmit={onSubmit}
            submitText={intl.formatMessage({ id: 'devise.invitations.edit.submit' })}
            errors={errors}
          />
        </Card>
      </Col>
    </Row>
  )
};

export default AcceptInvitation;