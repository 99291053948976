import AntForm, { AntSchema, useAntForm } from "@9troisquarts/ant-form";
import { Card, Col, Row } from "antd";
import React, { useState } from "react";
import { useIntl } from "react-intl";
import { UserType } from "../../types";
import { useHistory } from 'react-router-dom'
import { castAttributesFromDefinition } from '@9troisquarts/wand'
import axiosClient from '../../../utils/axiosClient';

type ResetPasswordProps = {
  user: UserType;
};

const userDefinition = {
  email: 'String',
};

const ResetPassword: React.FC<ResetPasswordProps> = props => {
  const {
    user,
  } = props;
  const history = useHistory();

  const resetPassword = (user: UserType) => axiosClient.post('/users/password', {
    user: { ...user }
  }).then((res) => {
    window.location.replace('/')
  }).catch(err => {
    setErrors(err.errors)
  });

  const onSubmit = () => resetPassword(castAttributesFromDefinition(userDefinition, object));

  const { object, onChange } = useAntForm(user || { 'email': '' });
  const [errors, setErrors] = useState<any | undefined>(undefined);
  const intl = useIntl();
  const ResetPasswordSchema: AntSchema = [
    {
      name: 'email',
      label: intl.formatMessage({ id: 'devise.mailer.reset_password_instructions.enter_email_address' }),
      input: {
        type: 'string',
      }
    }
  ]

  return (
    <Row style={{ padding: '10rem', minHeight: '800px' }}>
      <Col xs={12} md={12} offset={6}>
        <Card>
          <AntForm
            schema={ResetPasswordSchema}
            object={object}
            layout="vertical"
            // @ts-ignore
            onChange={onChange}
            onSubmit={onSubmit}
            submitText={intl.formatMessage({ id: 'words.submit' })}
            errors={errors}
          />
        </Card>
      </Col>
    </Row>
  )
};

export default ResetPassword;